import React, {useState, useEffect, useRef, useCallback} from 'react';
import { DndProvider, useDrag, useDrop, DragPreviewImage } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { gsap } from 'gsap';
import { usePreview } from 'react-dnd-preview';
import './App.css';
import { polyfill, scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop';
import 'mobile-drag-drop/default.css';

import {Autoplay, Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import ScreenGuard from "./components/ScreenGuard/ScreenGuard";

const ItemType = 'ITEM';

polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

const CustomDragLayer = () => {
  const { display, item, style } = usePreview();

  if (!display) {
    return null;
  }

  const preview = (
      <div style={style} className="drag-preview">
        <img src={item.item.image} alt={item.item.name} onLoad={() => {}} />
      </div>
  );

  return preview;
};


const DraggableImage = ({ item, startGame, setDragging }) => {
  const [{ isDragging }, ref, preview] = useDrag({
    type: ItemType,
    item: () => {
      startGame();
      setDragging(item.name, true);
      return { item };
    },
    end: () => {
      setDragging(item.name, false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
      <>
        <DragPreviewImage connect={preview} src={item.image} />
        <img ref={ref} src={item.image} alt={item.name} className="draggable-image" style={{ opacity: isDragging || item.isDropped ? 0 : 1 }} />
      </>
  );
};

const CargoItem = ({ item, index, startGame, setDragging }) => {
  return (
      <div className={`cargo-item cargo-item-${item.size}`}>
        <div className="cargo-item-wrapper">
          <DraggableImage item={item} startGame={startGame} setDragging={setDragging} />
        </div>
        <p>{item.name}</p>
      </div>
  );
};

const DropContainer = ({ id, acceptItem, item, onDrop, display }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType,
    drop: (draggedItem) => {
      onDrop(draggedItem.item);
    },
    canDrop: (draggedItem) => draggedItem.item.name === acceptItem,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
      <div ref={drop} className={`drop-container ${isOver && canDrop ? 'highlight' : ''}`} key={`drop-container-${id}`}>
        {item && item.isDropped && display ? <img src={item.image} alt={item.name} className="dropped-image" /> : ""}
      </div>
  );
};


const App = () => {

  useEffect(() => {
    polyfill({
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    });

    const forceUpdate = () => {
      setContainers((prev) => ({ ...prev }));
      setToolbox((prev) => [...prev]);
    };

    forceUpdate();
  }, []);

  const initialItems = [
    {
      name: 'Horse container',
      image: process.env.PUBLIC_URL + '/assets/images/container-horse@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Cars',
      image: process.env.PUBLIC_URL + '/assets/images/container-cars-V2@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Big pallets',
      image: process.env.PUBLIC_URL + '/assets/images/container-big-pallet@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Fruit container',
      image: process.env.PUBLIC_URL + '/assets/images/container-fruit@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Fish container',
      image: process.env.PUBLIC_URL + '/assets/images/container-fish@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Super heavy container',
      image: process.env.PUBLIC_URL + '/assets/images/container-super-heavy@2x.png',
      size: 'big',
      isDropped: false,
      isDragging: false
    }, {
      name: '2 aircraft engines',
      image: process.env.PUBLIC_URL + '/assets/images/container-engines-V2@2x.png',
      size: 'medium',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Flower container',
      image: process.env.PUBLIC_URL + '/assets/images/container-flower@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Medium pallets',
      image: process.env.PUBLIC_URL + '/assets/images/container-medium-2pallets-V3@2x.png',
      size: 'medium',
      isDropped: false,
      isDragging: false
    }, {
      name: 'Small pallets',
      image: process.env.PUBLIC_URL + '/assets/images/container-small-pallets@2x.png',
      size: 'small',
      isDropped: false,
      isDragging: false
    }
  ];

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const [containers, setContainers] = useState(
      initialItems.reduce((acc, item) => ({ ...acc, [item.name]: { ...item, isDropped: false } }), {})
  );
  const [toolbox, setToolbox] = useState(shuffleArray([...initialItems]));

  const setDragging = (name, isDragging) => {
    setToolbox((prevToolbox) => prevToolbox.map(item => item.name === name ? { ...item, isDragging } : item));
  };

  const timeAmount = 90;

  const [playerName, setPlayerName] = useState('');
  const [playerScore, setPlayerScore] = useState(0);

  const [timeLeft, setTimeLeft] = useState(timeAmount);

  const formatTime = (theTimeToFormat) => {

    let minutes = Math.floor(theTimeToFormat / 60);
    let secondes = Math.floor(theTimeToFormat % 60);

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (secondes < 10) {
      secondes = '0' + secondes;
    }

    return minutes + ':' + secondes;
  }

  const [gameStarted, setGameStarted] = useState(false);

  const [gameOver, setGameOver] = useState(false);
  const [win, setWin] = useState(false);

  const animateTimeDisplayer = (percentagePassed, animationDuration = 1) => {
    gsap.to('.app-timer-passed', {
      width: percentagePassed + '%',
      duration: animationDuration,
      ease: 'none'
    });
  }

  useEffect(() => {
    if (timeLeft > 0 && gameStarted && !win) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
        const percentagePassed = (100 - Math.ceil(timeLeft/timeAmount*100));
        animateTimeDisplayer(percentagePassed);
      }, 1000);
      return () => clearInterval(timerId);
    }
    else if (timeLeft === 0) {
      animateTimeDisplayer(100);
      setGameOver(true);
    }
  }, [timeLeft, gameStarted, win]);

  const startGame = () => {
    if (!gameStarted) {
      setGameStarted(true);
    }
  };

  const checkWinCondition = useCallback(() => {
    const allItemsPlaced = Object.keys(containers).every(
        (key) => containers[key] && containers[key].name === key && containers[key].isDropped
    );
    if (allItemsPlaced && gameStarted) {
      setGameOver(false);
      setWin(true);
    }
  }, [containers, gameStarted]);

  useEffect(() => {
    if (gameStarted) {
      setTimeout(() => checkWinCondition(), 100);
    }
  }, [containers, gameStarted, checkWinCondition]);

  useEffect(() => {
    if (gameOver === true) {
      fromGameToLoose();
    }
  }, [gameOver]);

  useEffect(() => {
    if (win === true) {
      fromGameToWin();
    }
  }, [win]);

  const clouds = [0, 1, 2, 3, 4, 5];

  useEffect(() => {

    const appCloudDuration = 8;
    const appCloudX = window.innerWidth;

    const appClouds = [1, 3, 2, 5, 4, 0];

    const appCloudY = -1 * window.innerWidth / 2.6 / appClouds.length;

    appClouds.forEach((appCloud, appCloudIndex) => {

      const c = document.querySelector(`.app-cloud-${appCloudIndex}`);
      const cBottom = (-1 * appCloudY * appCloud) + appCloudY;
      gsap.set(c, { bottom: cBottom });

      gsap.to(c, {
        x: appCloudX,
        y: appCloudY,
        duration: appCloudDuration,
        repeat: -1,
        ease: 'linear',
        delay: appCloudDuration / appClouds.length * appCloudIndex
      });
    });

    const handleResize = () => window.location.reload();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const fromScreensaverToStart = () => {

    const appLogoImg = document.querySelector('.app-logo img');
    const appLogoP = document.querySelector('.app-logo p');

    const screensaverScreen = document.querySelector('.app-screen-screensaver');
    const screenStart = document.querySelector('.app-screen-game');

    const startScreenBtn = document.querySelector('.app-screen-start .app-btn');
    const startScreenP = document.querySelector('.app-screen-start p');

    const appPlanes = document.querySelectorAll('.app-plane');

    const appQuit = document.querySelector('.app-quit');

    gsap.to(appLogoImg, { width: appLogoImg.offsetWidth / 1.5, duration: 1 });
    gsap.to(appLogoP, { opacity: 0, duration: 1 });
    gsap.to('.app-screen-screensaver p, .app-screen-screensaver .app-btn', { y: '25svh', duration: 1 });

    gsap.set(screensaverScreen, { display: 'none', delay: 1.1 })
    gsap.set(screenStart, { display: 'block', delay: 1.1 })

    gsap.to(startScreenBtn, { opacity: 1, duration: 1, delay: 1.1 });
    gsap.to(startScreenP, { opacity: 1, duration: 1, delay: 1.1 });

    appPlanes.forEach((appPlane, appPlaneIndex) => {
      gsap.to(appPlane, { width: '95svw', top: '40svh', duration: 1, delay: 1.1 });
    });

    gsap.to(appQuit, { right: '1svw', duration: .25, delay: 2.5 });

    setTimeout(() => {

      var planeEl = appPlanes[0];
      var planeRect = planeEl.getBoundingClientRect();

      var appDropContainerHeight = document.querySelector('.app-drop-containers').offsetHeight;

      var dropContainerBottom = window.innerHeight - (planeRect.top + (planeRect.height  * .8442));
      var dropContainerWidth = planeRect.width * .63;

      var appDropContainerGsapObj = {
        bottom: dropContainerBottom,
        width: dropContainerWidth,
        x: window.innerWidth * .165
      };

      gsap.set('.app-drop-containers', appDropContainerGsapObj);
      gsap.set('.app-drop-containers-displayers', appDropContainerGsapObj);

      console.log(planeRect, dropContainerBottom, appDropContainerHeight);
    }, 2500)
  }

  const fromStartToScreensaver = () => {

    const appLogoImg = document.querySelector('.app-logo img');
    const appLogoP = document.querySelector('.app-logo p');

    const screensaverScreen = document.querySelector('.app-screen-screensaver');

    const startScreenBtn = document.querySelector('.app-screen-start .app-btn');
    const startScreenP = document.querySelector('.app-screen-start p');

    const appPlane = document.querySelectorAll('.app-plane');

    const appQuit = document.querySelector('.app-quit');

    gsap.to(appQuit, { right: '-10svw', duration: .25 });

    gsap.to(startScreenBtn, { opacity: 0, duration: 1 });
    gsap.to(startScreenP, { opacity: 0, duration: 1 });
    gsap.to(appPlane, { width: '65svw', top: '50svh', duration: 1 })

    gsap.set(screensaverScreen, { display: 'block', delay: 1.1 })

    gsap.to(appLogoImg, { width: appLogoImg.offsetWidth * 1.5, duration: 1, delay: 1.5 });
    gsap.to(appLogoP, { opacity: 1, duration: 1, delay: 1.5 });
    gsap.to('.app-screen-screensaver p, .app-screen-screensaver .app-btn', { y: '0', duration: 1, delay: 1.5 });

  }

  const fromStartToGame = () => {

    const screenStart = document.querySelector('.app-screen-start');
    const screenGame = document.querySelector('.app-screen-game');

    const appPlaneFull = document.querySelector('.app-plane-full');
    const appPlaneCargo = document.querySelector('.app-plane-cargo');

    const toolbox = document.querySelector('.app-toolbox');

    gsap.to(screenStart, { opacity: 0, duration: 1 });
    gsap.set(screenStart, { display: 'none', delay: 1 });

    gsap.to(screenGame, { opacity: 1, duration: .5, delay: 1 });

    gsap.to(appPlaneFull, { opacity: 0, duration: 1, delay: 2 });
    gsap.to(appPlaneCargo, { opacity: 0, duration: 1, delay: 3 });

    gsap.to(toolbox, { bottom: '8svh', duration: .5, delay: 3.5 });
  }

  const fromGameToStart = () => {

    const screenGame = document.querySelector('.app-screen-game');
    const screenStart = document.querySelector('.app-screen-start');

    const appPlaneFull = document.querySelector('.app-plane-full');
    const appPlaneCargo = document.querySelector('.app-plane-cargo');

    const toolbox = document.querySelector('.app-toolbox');

    gsap.to(toolbox, { bottom: '-100%', duration: .5 });

    gsap.to(appPlaneCargo, { opacity: 1, duration: 1, });
    gsap.to(appPlaneFull, { opacity: 1, duration: 1});

    gsap.to(screenGame, { opacity: 0, duration: 1 });
    gsap.set(screenGame, { display: 'none', delay: 1 });

    gsap.set(screenStart, { display: 'block' });
    gsap.to(screenStart, { opacity: 1, duration: 1, delay: 1 });

    resetHints();
  }

  const fromGameToLoose = () => {

    const screenLoose = document.querySelector('.app-screen-looser');

    gsap.set(screenLoose, { display: 'block' });
    gsap.to(screenLoose, { opacity: 1, duration: .5 });
  }

  const fromGameToWin = () => {

    const screenWin = document.querySelector('.app-screen-win');

    gsap.set(screenWin, { display: 'block' });
    gsap.to(screenWin, { opacity: 1, duration: .5 });
  }

  const fromLooseToGame = () => {
    const screenLoose = document.querySelector('.app-screen-looser');

    gsap.to(screenLoose, { opacity: 0, duration: .5 });
    gsap.set(screenLoose, { display: 'none', delay: .5 });
  }

  const fromWinToGame = () => {

    const screenWin = document.querySelector('.app-screen-win');

    gsap.to(screenWin, { opacity: 0, duration: .5 });
    gsap.set(screenWin, { display: 'none', delay: .5 });
  }

  const fromGameToLeaderboard = () => {

    const screenScreensaverP = document.querySelector('.app-screen-screensaver p');

    const screenLeaderboard = document.querySelector('.app-screen-leaderboard');
    const leaderboardQuit = document.querySelector('.app-leaderboard-quit');

    gsap.set(screenScreensaverP, { opacity: 0 });

    gsap.set(screenLeaderboard, { display: 'block' });
    gsap.to(screenLeaderboard, { opacity: 1, duration: .5 });

    gsap.to(leaderboardQuit, { right: '1svw', duration: .25, delay: .5 });
  }

  const fromLeaderboardToScreensaver = () => {
    const screenLeaderboard = document.querySelector('.app-screen-leaderboard');

    gsap.to(screenLeaderboard, { opacity: 0, duration: .5 });
    gsap.set(screenLeaderboard, { display: 'none', delay: .5 });

    const screenScreensaverP = document.querySelector('.app-screen-screensaver p');
    gsap.to(screenScreensaverP, { opacity: 1, delay: .5 });
  }

  const handleScreenSaverClick = () => {
    fromScreensaverToStart();
  };

  const handleScreenStartClick = () => {
    fromStartToGame();
    fromGameToRules();
  };

  const handleQuitClick = () => {
    fromGameToStart();
    fromStartToScreensaver();
    resetGame();
  };

  const handleLeaderboardQuitClick = () => {
    const leaderboardQuit = document.querySelector('.app-leaderboard-quit');
    gsap.to(leaderboardQuit, { right: '-10svw', duration: .25 });
    fromLeaderboardToScreensaver();
  }

  const handleTryAgainClick = () => {
    resetGame();
    fromLooseToGame();
    fromWinToGame();
  }

  const handleLeaderboardClick = () => {

    if (win) {
      const score = timeAmount - timeLeft;
      setPlayerName('');
      setPlayerScore(score);
    }

    resetGame();
    fromGameToLeaderboard();
    fromLooseToGame();
    fromWinToGame();
    fromGameToStart();
    fromStartToScreensaver();
  }

  const resetGame = () => {
    setContainers(initialItems.reduce((acc, item) => ({ ...acc, [item.name]: null }), {}));
    setToolbox(shuffleArray([...initialItems]));
    setTimeLeft(timeAmount);
    setGameStarted(false);
    setGameOver(false);
    setWin(false);
    animateTimeDisplayer(0, .3);
  };

  const swiperRef = useRef(null);

  const resetHints = () => {
    gsap.to('.app-hints-slider', { opacity: 0, duration: .5, onComplete: () => {
      console.log(swiperRef.current);
        if (swiperRef.current) {
          swiperRef.current.slideTo(0);
        }
    } })
  }

  const moveItemToContainer = (item) => {
    setToolbox((prevToolbox) =>
        prevToolbox.map((i) => i.name === item.name ? { ...i, isDragging: false, isDropped: true } : i)
    );
    setContainers((prevContainers) => ({
      ...prevContainers,
      [item.name]: { ...item, isDropped: true }
    }));
  };

  const saveScore = (name, score) => {
    const leaderboard = JSON.parse(localStorage.getItem('leaderboard')) || [];
    leaderboard.push({ name, score });
    localStorage.setItem('leaderboard', JSON.stringify(leaderboard));
  };

  const getLeaderboard = () => {
    const leaderboard = JSON.parse(localStorage.getItem('leaderboard')) || [];
    return leaderboard.sort((a, b) => a.score - b.score);
  };

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const backend = isTouchDevice() ? TouchBackend : HTML5Backend;

  let formInserted = false;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    saveScore(playerName, playerScore);
    setPlayerName('');
    setPlayerScore(0);
  };

  const fromRulesToGame = () => {
    const rulesScreen = document.querySelector('.app-screen-rules');
    const rulesModal = document.querySelector('.app-modal-rules');

    gsap.to(rulesModal, { top: '150svh', duration: .5, });
    gsap.set(rulesScreen, { display: 'none', opacity: 0, delay: .5 });
  }

  const fromGameToRules = () => {
    const rulesScreen = document.querySelector('.app-screen-rules');
    const rulesModal = document.querySelector('.app-modal-rules');

    gsap.set(rulesScreen, { display: 'block', opacity: 1, });
    gsap.fromTo(rulesModal, { top: '150svh', duration: .5 }, { top: '50svh', delay: 4 });
  }

  const handleRulesModalClick = (e) => {
    fromRulesToGame();
  }

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [formInserted]);

  const handleHintClick = () => {
    gsap.to('.app-hints-slider', { opacity: 1, duration: .5 })
  }

  return (
      <DndProvider backend={backend}>
        <div className="app">

          <img src={`${process.env.PUBLIC_URL}/assets/images/ground.png`} className="app-ground" alt="ground"/>

          {clouds.map((cloud, cloudIndex) => (
              <img
                  src={`${process.env.PUBLIC_URL}/assets/images/nuage_z${cloud}.png`}
                  className={`app-cloud app-cloud-${cloudIndex}`}
                  key={`app-cloud-${cloudIndex}`}
                  alt={`cloud ${cloudIndex}`}
              />
          ))}

          <div className="app-screen app-screen-game">

            <div className="app-timer">
              <div className="app-timer-rest">{formatTime(timeLeft)}</div>
              <div className="app-timer-time">
                <div className="app-timer-icon">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon-chrono.png`} alt="icone chrono"/>
                </div>
                <div className="app-timer-full">
                  <div className="app-timer-passed"></div>
                </div>
              </div>
              <div className="app-timer-quick">
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon-flash.svg`} alt="icone flash"/>
                <p>Be <strong>quick!</strong></p>
              </div>
            </div>

            <div className="app-toolbox">
            {toolbox.map((item, index) => (
                  <CargoItem key={item.name} item={item} index={index} startGame={startGame} setDragging={setDragging}/>
              ))}
            </div>

            <div className="app-hints">
              <div className="app-hints-btn" onClick={handleHintClick}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon-hint.png`} alt="icone hint"/>
                <p><strong>Hint</strong></p>
              </div>
              <div className="app-hints-slider">
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                >
                  <SwiperSlide>
                    <div className="app-hints-slide">
                      <strong>Hint 1</strong><p>Some items have a dedicated spot on the aircraft</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="app-hints-slide">
                      <strong>Hint 2</strong>
                      <p>The heaviest items need to be placed at the center of the aircraft and the lightest items need to be placed at the ends of the aircraft</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>

          </div>

          <div className="app-screen app-screen-start" onClick={handleScreenStartClick}>
            <div className="app-btn app-btn-lg">START GAME</div>
            <p>Find the <strong>ideal loading configuration</strong> for the A350F</p>
          </div>

          <div className="app-screen app-screen-screensaver" onClick={handleScreenSaverClick}>
            <div className="app-btn app-btn-lg">THE GAME</div>
            <p>Touch the screen to begin</p>
          </div>

          <div className="app-quit" onClick={handleQuitClick}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/icon-quit.png`} alt="quit"/>
            <p>Quit</p>
          </div>

          <div className="app-leaderboard-quit" onClick={handleLeaderboardQuitClick}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/icon-quit.png`} alt="quit"/>
            <p>Quit</p>
          </div>

          <div className="app-logo">
            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-airbusa350f.png`} alt="logo"/>
            <p>Look ahead with <strong>a new generation freighter</strong></p>
          </div>

          <img src={`${process.env.PUBLIC_URL}/assets/images/A350F_RR_AIB_VCUT.png`} className="app-plane" alt="plane"/>

          <div className="app-drop-containers-displayers">
            {Object.keys(containers).map((key) => (
                <DropContainer
                    key={key}
                    id={key}
                    acceptItem={key}
                    item={containers[key]}
                    onDrop={moveItemToContainer}
                    display={true}
                />
            ))}
          </div>

          <img src={`${process.env.PUBLIC_URL}/assets/images/A350F_RR_AIB_VCUT-FRONT.png`} className="app-plane" alt="plane"/>
          <img src={`${process.env.PUBLIC_URL}/assets/images/A350F_RR_AIB_VCARGO.png`} className="app-plane app-plane-cargo" alt="plane"/>
          <img src={`${process.env.PUBLIC_URL}/assets/images/A350F_RR_AIB_VFULL.png`} className="app-plane app-plane-full" alt="plane"/>

          <div className="app-drop-containers">
            {Object.keys(containers).map((key) => (
                <DropContainer
                    key={key}
                    id={key}
                    acceptItem={key}
                    item={containers[key]}
                    onDrop={moveItemToContainer}
                    display={false}
                />
            ))}
          </div>

          <div className="app-screen app-screen-looser">
            <div className="app-modal app-bg-gray">
              <p className="app-modal-title">SORRY</p>
              <p className="app-modal-description">Time's up!</p>
              <p className="app-modal-time">{formatTime(timeAmount)}</p>
              <div className="app-modal-actions">
                <button className="app-btn app-btn-sm app-btn-secondary" onClick={handleLeaderboardClick}>SHOW LEADERBOARD</button>
                <button className="app-btn app-btn-sm app-btn-secondary" onClick={handleTryAgainClick}>TRY AGAIN</button>
              </div>
            </div>
          </div>

          <div className="app-screen app-screen-win">
            <div className="app-modal app-bg-orange">
              <p className="app-modal-title">CONGRATULATIONS!</p>
              <p className="app-modal-description">you loaded the A350F in</p>
              <p className="app-modal-time">{formatTime(timeAmount - timeLeft)}</p>
              <div className="app-modal-actions">
                <button className="app-btn app-btn-sm app-btn-secondary app-bg-orange" onClick={handleLeaderboardClick}>SUBMIT SCORE</button>
                <button className="app-btn app-btn-sm app-btn-secondary app-bg-orange" onClick={handleTryAgainClick}>TRY
                  AGAIN
                </button>
              </div>
            </div>
          </div>

          <div className="app-screen app-screen-rules">
            <div className="app-modal app-modal-rules">
              <p><strong>Drag and drop</strong> each item in its right place inside the A350F</p>
              <img src={`${process.env.PUBLIC_URL}/assets/images/icon-chrono-black.svg`} className="" alt="chrono"/>
              <p>The timer starts <strong>at the first drag</strong></p>
              <button type="button" className="app-btn app-btn-lg" onClick={handleRulesModalClick}>OK</button>
            </div>
          </div>

          <div className="app-screen app-screen-leaderboard">
            <div className="leaderboard">
              <div className="leaderboard-header">
                Leaderboard
              </div>
              <div className="leaderboard-body">
                {getLeaderboard().map((entry, index) => {
                  if (!formInserted && playerScore <= entry.score && playerScore !== 0) {
                    formInserted = true;
                    setTimeout(() => formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 0); // Scroll to the form
                    return (
                        <React.Fragment key={`form-${index}`}>
                          <div className="leaderboard-body-score">
                            <div className="leaderboard-body-score-index">{index + 1}-</div>
                            <div className="leaderboard-body-score-name">
                              <form ref={formRef} onSubmit={handleFormSubmit}>
                                <input type="text" placeholder="" value={playerName} onChange={(e) => setPlayerName(e.target.value)} autoFocus={true} />
                                <button type="submit" className="app-btn app-btn-sm">Save</button>
                              </form>
                            </div>
                            <div className="leaderboard-body-score-score">{formatTime(playerScore)}</div>
                          </div>
                          <div className="leaderboard-body-score" key={index}>
                            <div className="leaderboard-body-score-index">{index + 2}-</div>
                            <div className="leaderboard-body-score-name">{entry.name}</div>
                            <div className="leaderboard-body-score-score">{formatTime(entry.score)}</div>
                          </div>
                        </React.Fragment>
                    );
                  }
                  return (
                      <div className="leaderboard-body-score" key={index}>
                        <div className="leaderboard-body-score-index">{index + 1}-</div>
                        <div className="leaderboard-body-score-name">{entry.name}</div>
                        <div className="leaderboard-body-score-score">{formatTime(entry.score)}</div>
                      </div>
                  );
                })}
                {(!formInserted && playerScore !== 0) && (
                  <div className="leaderboard-body-score" key="form-end">
                    <div className="leaderboard-body-score-index">{getLeaderboard().length + 1}-</div>
                    <div className="leaderboard-body-score-name">
                      <form ref={formRef} onSubmit={handleFormSubmit}>
                        <input type="text" placeholder="" value={playerName} onChange={(e) => setPlayerName(e.target.value)} autoFocus={true} />
                        <button type="submit" className="app-btn app-btn-sm">Save</button>
                      </form>
                    </div>
                    <div className="leaderboard-body-score-score">{formatTime(playerScore)}</div>
                  </div>
                )}
              </div>
              <div className="leaderboard-footer"></div>
            </div>
          </div>

        </div>

        <CustomDragLayer/>

        <ScreenGuard/>

      </DndProvider>
  );
};

export default App;
