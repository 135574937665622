import React, { useEffect, useState } from 'react';
import './ScreenGuard.css';

const ScreenGuard = ({ children }) => {
    const [isAllowed, setIsAllowed] = useState(true);

    const checkScreen = () => {
        const isLandscape = window.matchMedia('(orientation: landscape)').matches;
        const isMinWidth = window.innerWidth >= 767;
        setIsAllowed(isLandscape && isMinWidth);
    };

    useEffect(() => {
        checkScreen();
        window.addEventListener('resize', checkScreen);
        window.addEventListener('orientationchange', checkScreen);

        return () => {
            window.removeEventListener('resize', checkScreen);
            window.removeEventListener('orientationchange', checkScreen);
        };
    }, []);

    if (!isAllowed) {
        return (
            <div className="app-screen-guard">
                <p>
                    To play this game, use at least a <br/>
                    <strong>small tablet in landscape mode</strong>.
                </p>
            </div>
        );
    }

    return children;
};

export default ScreenGuard;
